import React from 'react'
import PropTypes from 'prop-types'
import { ErrorView } from '@level'
import { useProfile } from '@app/hooks/useProfile'
import {
  ErrorBoundary,
  BugsnagContext,
} from '@app/hooks/useBugsnag'

const BugsnagIdentify = () => {
  const Bugsnag = React.useContext(BugsnagContext)
  const { Profile: { id: userId, name, email } } = useProfile()

  React.useEffect(() => {
    if (userId && Bugsnag) {
      Bugsnag.setUser(userId, email, name)
    }
  }, [userId])

  return null
}

const ErrorViewWrapper = ({ FallbackComponent = ErrorView, ...rest }) => {
  const View = (props) => {
    const { getUser } = React.useContext(BugsnagContext)
    const showLog = import.meta.env.VITE_NODE_ENV === 'development' || getUser()?.email?.endsWith('@enveloop.com')
    return <FallbackComponent showLog={showLog} {...rest} {...props} />
  }

  return <ErrorBoundary FallbackComponent={View} {...rest} />
}

const BugsnagProvider = ({ children, errorView = ErrorView }) => {
  const Bugsnag = React.useContext(BugsnagContext)

  // ignore Bugsnag if no API key set
  if (!import.meta.env.VITE_BUGSNAG_API_KEY) return children

  return (
    <BugsnagContext.Provider value={Bugsnag}>
      <ErrorViewWrapper layout FallbackComponent={errorView}>
        {children}
      </ErrorViewWrapper>
    </BugsnagContext.Provider>
  )
}

BugsnagProvider.propTypes = {
  children: PropTypes.node.isRequired,
  errorView: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

export {
  BugsnagProvider,
  BugsnagIdentify,
  ErrorViewWrapper as ErrorBoundary,
}
