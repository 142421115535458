import PropTypes from 'prop-types'

const Preview = ({
  zoom = 0.50,
  style = {},
  children,
  className,
  overflow = 'hidden',
  ...rest
}) => {
  const width = `${Math.floor(100 / zoom)}%`
  // const height = overflow === 'hidden' ? `${Math.ceil(100 / zoom)}%` : '100%'
  const height = `${Math.ceil(100 / zoom)}%`
  return (
    <div
      className={`level-preview ${className || ''}`}
      style={{
        pointerEvents: 'none',
        position: 'relative',
        overflow: 'visible',
        height,
        width,
        transform: `scale(${zoom})`,
        transformOrigin: 'top left',
        ...style,
      }}
      {...rest}
    >{children}
    </div>
  )
}

Preview.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  overflow: PropTypes.string,
  zoom: PropTypes.number,
  style: PropTypes.object,
}

export {
  Preview,
}
