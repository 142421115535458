import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Main } from '../Layout'
import { Stack } from '../Stack'
import { Shelf } from '../Shelf'
import { Card } from '../Card'
import { Text } from '../Text'
import { Icon } from '../Icon'
import { Button } from '../Button'

const Link = ({ to, ...props }) => (<a href={to} className="level-link" {...props} />)
const useDashboardUrl = () => {
  const { pathname } = useLocation()
  return (pathname.includes('teams'))
    ? `/${pathname.match(/teams\/[^/]+/)[0]}`
    : null
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
}

const ErrorLayout = ({ children }) => {

  return (
    <div className="level-layout-wrapper">
      <div className="level-layout">
        <Main>
          <Stack>
            <Shelf align="left" space={7}>
              <Icon name="enveloop-logomark" fill="none" size={9} />
            </Shelf>
            {children}
          </Stack>
        </Main>
      </div>
    </div>
  )
}

ErrorLayout.propTypes = {
  children: PropTypes.node,
}

const ErrorCard = ({ children, width = 600, action, actionTo, actionText }) => {
  const dashboardUrl = useDashboardUrl()
  return (
    <Stack valign="center" align="center" gap={8}>
      <Card space={10} style={{ width: `${width}px` }}>
        <Stack valign="center" align="center" gap={9}>
          <Icon name="triangle-error" size={10} />
          <Stack align="center" gap={4}>
            <Text tag="h2" align="center">Enveloop has encountered a problem...</Text>
            <Text align="center" style={{ width: '70%' }}>Our team has been notified. Try refreshing this page or return back to your team dashboard.</Text>
          </Stack>
          <Stack align="center" gap={4}>
            {action || actionTo ? (
              <Button onClick={action} to={actionTo} theme="primary">{actionText}</Button>
            ) : (
              <Button as={Link} theme="primary" to={dashboardUrl}>Return to Dashboard</Button>
            )}
            <Link to="mailto:support@enveloop.com">Contact Support</Link>
          </Stack>
          {/* return detailed info and debug information in development environment */}
          {children}
        </Stack>
      </Card>
    </Stack>
  )
}

ErrorCard.propTypes = {
  children: PropTypes.node,
  width: PropTypes.number,
}

const ErrorView = ({ error, info, showLog = false, layout = false, ...rest }) => {
  // return detailed info and debug information in development environment
  const card = (
    <ErrorCard width={900} {...rest}>
      {showLog ? (<Stack gap={5}>
        <Text tag="h1">{error.message}</Text>
        <Text tag="pre" style={{ whiteSpace: 'pre-wrap' }}>{error.stack}</Text>
        <Text tag="h3">Component Stack</Text>
        <Text tag="pre">{info.componentStack}</Text>
      </Stack>) : null}
    </ErrorCard>
  )
  if (layout) return <ErrorLayout>{card}</ErrorLayout>
  return card
}

ErrorView.propTypes = {
  error: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  layout: PropTypes.bool,
  showLog: PropTypes.bool,
}

export {
  ErrorView,
}
