import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@app/hooks'
import {
  Template, Stack, Text, Shelf, Loading, BlankSlate, Button,
  searchToObject,
} from '@level'
import { templateQueryDefaults } from './queries'

const templateQuery = ({ templateId, projectId, teamId }) => `
  team(team: "${teamId}") {
    plan { watermark }
  }
  template(slug: "${templateId}", project: "${projectId}", team: "${teamId}") {
    ${templateQueryDefaults}
  }
`

const TemplateView = () => {
  const [params] = useSearchParams()
  const [getTemplateData, { data, isLoading, queryCount }] = useQuery(templateQuery)
  const {
    teamId, projectId, templateId, view,
  } = searchToObject(params)

  React.useEffect(() => {
    if (teamId && projectId && templateId) {
      getTemplateData({
        teamId, projectId, templateId, view,
      })
    }
  }, [teamId, projectId, templateId, view])

  if (!queryCount || isLoading) return <Loading />
  if (!data?.template) {
    return (
      <BlankSlate
        icon="template"
        heading="Template Not Found"
        description="This Template may have been deleted or the template slug has changed."
        buttonText="Close Window"
        buttonAction={window.close}
      />
    )
  }

  const {
    template: { liveConfig, previewConfig, type }, team: { plan: { watermark } },
  } = data

  return (
    <Stack gap={5} space={[8, 0, 0]}>
      <Shelf space={[0, 8, 4]} align="split">
        <Text icon={{ name: 'circle-info', color: 'blue-600' }} size={2}>You are viewing the published version of this template.</Text>
        {window.isChild ? <Button onClick={window.close} text="Close" /> : null}
      </Shelf>
      <Template config={view === 'live' ? liveConfig : previewConfig} mode="preview" type={type} hooks={{ watermark }} />
    </Stack>
  )
}

export { TemplateView }
