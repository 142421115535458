import { EmailTemplateBuilder } from '../email'
import { SMSTemplateBuilder } from '../sms'
import { useTemplate } from '../context'

const TemplateBuilder = () => {
  const { template: { type } } = useTemplate()

  if (type === 'email') return <EmailTemplateBuilder />
  if (type === 'sms') return <SMSTemplateBuilder />

  return <h1>Unsupported template type</h1>
}

export {
  TemplateBuilder,
}
