import { Main } from '@level'
import { useTeam } from '@app/hooks'

import { useTemplate } from '../context'

import { PreviewEmailTemplate } from './previewEmailTemplate'
import { DeployDrawer } from '../deploy/drawer'
import { TemplateVersion } from '../history'

const DeployEmailTemplate = () => {
  const { template } = useTemplate()
  const { Team } = useTeam()

  if (!template) {
    return null
  }

  // TODO: allow preview of published vs unpublished
  const config = template.previewConfig

  return (
    <Main drawer={<DeployDrawer />}>
      <PreviewEmailTemplate type={template.type} {...template} config={config} hooks={{ watermark: Team.plan.watermark }} mode="preview" />
      {/* <TemplateVersion type={template.type} {...template} config={config} hooks={{ watermark: Team.plan.watermark }} mode="preview" /> */}
    </Main>
  )
}

export { DeployEmailTemplate }
