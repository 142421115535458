import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const useSizeStore = create(
  persist(
    (set) => ({
      size: 'large',
      setSize: (newSize) => {
        if (['small', 'medium', 'large'].includes(newSize)) {
          set({ size: newSize });
        }
      },
    }),
    {
      name: 'template-preview-size',
      getStorage: () => localStorage,
    }
  )
);

// Now, create a custom hook that mimics useState
export const useSizeClass = () => {
  const size = useSizeStore((state) => state.size);
  const setSize = useSizeStore((state) => state.setSize);

  return [size, setSize];
}
