import React from 'react'
import { useDebounce } from './useDebounce'
import { useThrottle } from './useThrottle'
import { spaceToSize, toPadding } from '../helpers'

const onTransitionOnce = (element, callback) => {
  function handleTransition(event) {
    event.stopPropagation()
    callback(event)
    element?.removeEventListener('transitionend', handleTransition)
  }
  element?.addEventListener('transitionend', handleTransition)

  return () => {
    element?.removeEventListener('transitionend', handleTransition)
  }
}


const getContainer = ({ rootRef, contentRef, withinParent }) => {
  // Determine container element
  let containerElement = rootRef?.current
  if (!containerElement) {
    if (withinParent) {
      // Use parent element when withinParent is true
      containerElement = contentRef.current?.parentElement
    } else {
      // Default to document element (viewport)
      containerElement = document.documentElement
    }
  }
  return containerElement

}

const setSize = ({ rootRef, contentRef, footerRef, withinParent, limitHeight, padding }) => {
  const containerElement = getContainer({ rootRef, contentRef, withinParent })

  if (!contentRef.current) return
  const contentTop = contentRef.current.getBoundingClientRect().top
  let bottomOffset = footerRef.current?.getBoundingClientRect().height || 0
  if (withinParent) bottomOffset = bottomOffset + (window.innerHeight - containerElement.getBoundingClientRect().bottom)
  else bottomOffset = bottomOffset + padding

  const prop = limitHeight ? 'maxHeight' : 'height'
  contentRef.current.style.overflowY = 'auto'
  contentRef.current.style[prop] = `calc(100vh - ${Math.round(contentTop + bottomOffset)}px)`
}

const useMaxSize = ({
  contentRef: providedContentRef,
  footerRef: providedFooterRef,
  rootRef: providedRootRef,
  offsetSpace = 0,
  offsetPadding = 0,
  withinParent = false,
  limitHeight = false,
} = {}) => {
  // Generate refs if not provided
  const generatedContentRef = React.useRef(null)
  const generatedFooterRef = React.useRef(null)
  const generatedRootRef = React.useRef(null)
  const { bottom } = toPadding(spaceToSize(offsetSpace))
  const padding = offsetPadding || bottom

  // Use provided refs or generated refs
  const contentRef = providedContentRef || generatedContentRef
  const footerRef = providedFooterRef || generatedFooterRef
  const rootRef = providedRootRef || generatedRootRef

  const updateLayoutVars = useThrottle(() => {
    setSize({ rootRef, contentRef, footerRef, withinParent, limitHeight, padding })
  }, [contentRef, footerRef, limitHeight, padding, rootRef, withinParent], 10)

  React.useEffect(() => {
    const containerElement = getContainer({ rootRef, contentRef, withinParent })
    // Set up resize observer
    const resizeObserver = new ResizeObserver(updateLayoutVars)
    const removeTransition = onTransitionOnce(document.documentElement, () => {
      setTimeout(() => setSize({ rootRef, contentRef, footerRef, withinParent, limitHeight, padding }), 100)
    })

    // Observe container
    if (containerElement) {
      resizeObserver.observe(containerElement)
      updateLayoutVars()
    }

    updateLayoutVars()
    // Cleanup observers
    return () => {
      resizeObserver.disconnect()
      removeTransition()
    }
  }, [contentRef, footerRef, limitHeight, padding, rootRef, updateLayoutVars, withinParent])

  // Return refs and style
  return {
    contentRef,
    ref: contentRef,
    footerRef,
    rootRef,
  }
}

export { useMaxSize }
