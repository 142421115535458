import { useEffect, useState, useMemo } from 'react'
import { useQuery } from '@app/hooks'
import { useNavigate } from 'react-router-dom'
import {
  useDialog, formatDateTime, SliderInput, Block,
  useMaxSize, Divider, ScrollPanel, Stack,
  Text, Template, Grid, Button, Spacer, Shelf,
} from '@level'
import { SizeClassControls } from '../email/previewEmailTemplate'
import { ErrorBoundary } from '../../../components/Bugsnag'
import { useSharedSections } from "../../sharedSections/provider"
import { templateChangelogQuery, updateTemplateConfigQuery } from '../queries'
import { useSizeClass } from '../email/useSizeClass'
import { newSection } from '../../../level/components/Template/Email/Section'
import './history.scss'

const updateConfigSharedSections = ({ config, sharedSections }) => {
  const sections = config.sections.map((section) => {
    const { sharedSectionId } = section
    if (sharedSectionId) {
      const sharedSection = sharedSections.find(({ id }) => id === sharedSectionId)
      if (sharedSection) {
        try {
          const sec = newSection({
            section: {
              sharedSectionId,
              ...sharedSection.liveConfig,
            }, templateVariables: config.variables
          })
          return sec
        } catch (e) {
          console.error(e)
          console.log({ sharedSection, section })
          return section
        }
      }
      return section
    }
    return section
  })
  return {
    ...config,
    sections,
  }
}

// const ErrorView = () => (
//   <Card>
//     <CardHeader><h3>Ooops</h3></CardHeader>
//     <p>Looks like this Template has a rendering issue.</p>
//   </Card>
// )
//
const TemplateVersion = ({ version, config, sharedSections, ...rest }) => {
  const updatedConfig = useMemo(() => updateConfigSharedSections({ config, sharedSections }), [version, config, sharedSections])

  // hooks={{ watermark }} TODO: get this working
  return (
    <Template config={updatedConfig} {...rest} />
  )
}
// TODO: After restoring in place be sure to trigger the config to update so that the sidebar doesn't undo.
const TemplateVersions = ({
  close, template, fetchLiveChangelog, fetchPreviewChangelog, sharedSections, update, refresh, restoreToNew, navigate,
}) => {
  const [changes, setChanges] = useState([])
  const [published, setPublished] = useState([])

  // Extract latest change, and latest published
  const current = { config: template.previewConfig, publishedAt: template.updatedAt, type: 'current', published: template.published }
  const live = template.liveConfig ? { config: template.liveConfig, publishedAt: template.publishedAt, type: 'live', published: true } : null

  const configs = (() => {
    if (live && !current.published) {
      return [...published, live, ...changes, current]
    }
    return [...published, ...changes, current]
  })()

  const [index, setIndex] = useState(0)
  const liveIndex = configs.findIndex(c => c.published)

  // Fetch both changes and published configs
  useEffect(() => {
    if (!changes.length && !published.length) (async () => {
      fetchPreviewChangelog().then(({ data }) => {
        if (data?.template) {
          const changelog = data.template.previewConfigChangelog.map((c) => ({ ...c, type: 'preview' }))
          // The first preview item is the latest published version so we need to remove it.
          setChanges(changelog)
        }
      })
      fetchLiveChangelog().then(({ data }) => {
        if (data?.template) {
          const changelog = data.template.liveConfigChangelog.map((c) => ({ ...c, type: 'live' }))
          setPublished(changelog)
        }
      })
    })()
  }, [])

  const currentConfig = configs[index]
  const isLive = !!currentConfig?.published

  const restoreTemplate = async () => {
    await update({ id: template.id, previewConfig: updateConfigSharedSections({ config: currentConfig.config, sharedSections }) })
    await refresh()
    navigate('./refresh', { state: { from: location.pathname } })
    close()
  }

  const restoreToNewTemplate = async () => {
    await restoreToNew({ config: { ...template, previewConfig: updateConfigSharedSections({ config: currentConfig.config, sharedSections }) } })
    close()
  }

  useEffect(() => {
    if (configs.length) { setIndex(configs.length - 1) }
  }, [published, changes])

  const [sizeClass] = useSizeClass()
  const { footerRef, contentRef } = useMaxSize({ withinParent: true })
  const versionTitle = (() => {
    if (template.published) return <Text icon={{ name: 'star', color: 'green-600' }}>Published: {formatDateTime(currentConfig.publishedAt)} (Latest, Live)</Text>
    if (currentConfig.published) return <Text icon={{ name: 'star', color: 'green-600' }}>Published: {formatDateTime(currentConfig.publishedAt)} (Live)</Text>
    if (currentConfig?.type === 'current') return <Text icon={{ name: 'circle-small', color: 'orange-400' }}>Unpublished: {formatDateTime(currentConfig.publishedAt)} (Latest)</Text>
    if (currentConfig?.type === 'preview') return <Text icon={{ name: 'circle-small', color: 'orange-400' }}>Unpublished: {`${formatDateTime(currentConfig.publishedAt)}`}</Text>
    return <Text icon={{ name: 'star', color: 'neutral-100' }}>Published: {formatDateTime(currentConfig.publishedAt)}</Text>
  })() || ''

  return (
    <>
      <Grid gap={4} space={7} templateColumns="1fr 1fr" valign="center">
        <Stack gap={3}>
          <Text tag="h3" style={{ textAlign: 'left' }}>Template History</Text>
          {versionTitle}
        </Stack>
        <Shelf gap={4} align="right">
          <div style={{ textAlign: 'right' }}>
            {liveIndex !== -1 ? <Button text={isLive ? "Viewing Live" : "View Live"} className={isLive ? 'selected-green' : null} icon={{ name: 'eye', color: isLive ? 'green-700' : null }} onClick={() => setIndex(liveIndex)} /> : null}
          </div>
          <Spacer width={4} />
          <Button disabled={index === 0} tip="Previous Change" icon={{ name: 'arrow-left' }} onClick={() => setIndex((i) => i - 1)} />
          <Button disabled={index === configs.length - 1} tip="Next Change" icon={{ name: 'arrow-right' }} onClick={() => setIndex((i) => i + 1)} />
        </Shelf>
      </Grid>
      <Divider />
      <ScrollPanel ref={contentRef}>
        {configs.length > 1 ? (
          <Block background="white" style={{ position: 'sticky', top: 0, zIndex: 2 }}>
            <Grid templateColumns="auto 1fr" gap={5} space={[4, 7]}>
              <div>Versions</div>
              <SliderInput defaultValue={index} label={false} displayValue={false} onInput={({ target }) => setIndex(parseInt(target.value))} name="time" min={0} max={configs.length - 1} />
            </Grid>
            <Divider style={{ gridColumn: '1 / -1' }} />
          </Block>
        ) : null}
        <ErrorBoundary action={close} actionText="Dismiss">
          <div data-media-width={sizeClass} style={{ pointerEvents: 'none' }}>
            {currentConfig.config || template.previewConfig ? (
              <TemplateVersion
                version={index}
                sharedSections={sharedSections}
                type={template.type}
                mode="preview"
                config={currentConfig?.config || template.previewConfig}
              />
            ) : null}
          </div>
        </ErrorBoundary>
      </ScrollPanel >
      <Divider />
      <Grid templateColumns="auto auto" align="split" valign="center" ref={footerRef} space={7}>
        <SizeClassControls />
        <Shelf align="right" gap={4} reverse>
          <Button text="Restore" onClick={restoreTemplate} theme="primary-green" disabled={currentConfig?.type === 'current'} />
          <Button text="Restore To New Template" onClick={restoreToNewTemplate} theme="secondary-green" disabled={currentConfig?.type === 'current'} />
          <Button text="Cancel" onClick={close} />
        </Shelf>
      </Grid>
    </>
  )
}

const useTemplateVersions = ({ template, Project, refresh, restoreToNew }) => {
  const [fetchLiveChangelog] = useQuery(templateChangelogQuery({ type: 'live', Project, slug: template.slug }))
  const [fetchPreviewChangelog] = useQuery(templateChangelogQuery({ Project, slug: template.slug }))
  const [update] = useQuery(updateTemplateConfigQuery)
  const { sharedSections } = useSharedSections()
  const sendDialog = useDialog()
  const navigate = useNavigate()
  return () => sendDialog({
    width: "80vw",
    height: "80vh",
    element: TemplateVersions,
    template,
    Project,
    fetchLiveChangelog,
    fetchPreviewChangelog,
    sharedSections,
    update,
    refresh,
    restoreToNew,
    navigate,
  })
}

export {
  useTemplateVersions,
  TemplateVersion,
}
