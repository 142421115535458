import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  copyObject,
  elementThemeValues,
  includeKeys,
  isColor,
  sectionThemeValues, sortThemeColors,
  uniqueArray,
} from '../../../../helpers'
import { getTemplateElement, getTemplateSection } from '../../../../helpers/template'
import {
  getTemplateState,
  useChannel,
  useClipboard, useDebounce, useDialog, useTemplateRoot,
} from '../../../../hooks'
import { useKeyDown, useKeyUp } from '../../../../hooks/useKeyPress'
import { TemplateProvider } from '../../useTemplate'
import {
  addElementToSection,
  addSectionToTemplate,
  filterElementUpdate,
  removeElementFromSection,
  removeSectionFromTemplate,
  unlinkSharedSectionFromTemplate,
  updateElementInSection,
  updateSectionInTemplate,
  updateTemplateConfig,
} from '../helpers'
import { newSection } from '../Section'
import { usePasteThemeNotice } from '../usePasteTheme'

const TemplateConfigContext = React.createContext({})
const useTemplateConfig = () => React.useContext(TemplateConfigContext)

const scrollToEl = (id) => {
  if (document) {
    setTimeout(() => {
      const el = document.querySelector(`#${id}`)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }, 50)
  }
}

const useThemeHover = ({ config, onChange, deps = [] }) => {
  const color = React.useRef(null)
  const highlight = '#00ff7b'
  const replace = (obj, val1, val2) => (
    JSON.parse(JSON.stringify(obj).replaceAll(new RegExp(val1, 'gi'), val2))
  )
  useChannel('hover/theme', ({ data }) => {
    if (data && isColor.hex(data)) {
      color.current = data
      config.variables = replace(config.variables, color.current, highlight)
      config.sections = replace(config.sections, color.current, highlight)
    } else {
      config.variables = replace(config.variables, highlight, color.current)
      config.sections = replace(config.sections, highlight, color.current)
    }
    onChange()
  }, [config, ...deps])
}

const useTemplateConfigHooks = ({
  config: configProp,
  onChange: onChangeNow,
  uploadImage,
  saveToSharedSection,
  SharedSectionPanel,
  sharedSections,
  updateSharedSection,
  name,
  slug,
  root,
  queryCount,
  ...rest
}) => {
  const [config, setConfig] = React.useState(configProp)
  const [updated, setUpdated] = React.useState(0)
  const { copy, clear, inspect } = useClipboard()
  const sendDialog = useDialog()
  const navigate = useNavigate()
  const [dragging, setDragging] = React.useState()
  const [showDrawerMenu, setShowDrawerMenu] = React.useState(false)
  const pasteThemeNotice = usePasteThemeNotice()

  const theme = React.useMemo(() => (
    Object.values(config.variables._theme || {}).length
      ? sortThemeColors(config.variables._theme)
      : null
  ), [config.variables._theme, queryCount])

  // Trigger onChange if there has been a change
  const onChange = useDebounce((props) => (
    onChangeNow?.(props)
  ), [slug], 500)

  const triggerUpdate = (opts = {}) => {
    if (opts.now) onChangeNow?.({ config })
    else onChange({ config })
    setUpdated({})
  }

  const updateTemplate = (update = {}) => {
    updateTemplateConfig({ config, update })
    if (update.localOnly) {
      setUpdated({})
    } else {
      triggerUpdate()
    }
  }

  useThemeHover({ config, onChange: () => setUpdated({}) }, [updated])

  const saveTemplate = () => {
    onChange({ config })
  }

  // ===============
  // SECTION HELPERS
  // ===============

  const selectSection = (id) => {
    navigate(`section/${id}`, { replace: true })
    scrollToEl(id)
  }

  const updateSection = ({ section, update = {}, localOnly }) => {
    updateSectionInTemplate({ config, section, update })
    if (localOnly) {
      setUpdated({})
    } else {
      triggerUpdate()
    }
  }

  const addSection = ({ section, sectionId = section?.variables?.id }) => {
    const addedSection = addSectionToTemplate({ config, section, sectionId })
    triggerUpdate()

    selectSection(addedSection.variables.id)
    return addedSection
  }

  const confirmDeleteSection = ({ section }) => {
    const onConfirm = () => {
      removeSectionFromTemplate({ config, section })
      triggerUpdate({ now: true })
      navigate('./', { replace: true })
    }
    sendDialog({
      onConfirm,
      title: `Remove "${section.variables.config.name}"?`,
      confirmText: 'Remove',
      destructive: true,
    })
  }

  const addSharedSection = ({ section, sectionId }) => {
    const sec = addSection({
      sectionId,
      section: copyObject({
        ...section.liveConfig,
        sharedSectionId: section.id,
        variables: { ...section.liveConfig.variables },
      }),
    })
    selectSection(sec.variables.id)
  }

  const convertToSharedSection = async (section) => {
    if (saveToSharedSection) {
      const response = await saveToSharedSection({ section, config, publish: true })
      if (response && response?.data?.sharedSection) {
        const { data: { sharedSection } } = response

        if (sharedSection) {
          updateSection({
            section,
            update: {
              sharedSectionId: sharedSection.id,
              config: { name: sharedSection.name },
            },
          })
        }
      }
    }
  }

  const unlinkSharedSection = (section) => {
    unlinkSharedSectionFromTemplate({ config, section })
    triggerUpdate({ now: true })
  }

  const changeSectionOrder = ({ from, to }) => {
    config.sections.splice(to, 0, config.sections.splice(from, 1)[0])
    triggerUpdate()
  }

  // ===============
  // ELEMENT HELPERS
  // ===============

  // Get an element and its place in a section
  // element, elementId: Accepts an element object or an elementId
  // Returns: a sectionElement ({ section, element, columnIndex, elementIndex })
  const getSectionElement = ({ element, elementId = element?.id }) => (
    getTemplateElement({ config, elementId })
  )

  const selectElement = (element) => {
    navigate(`./element/${element.id}`, { replace: true })
    scrollToEl(element.id)
  }

  const addElement = ({ element, sectionId, elementId }) => {
    const { element: newElement } = addElementToSection({
      config,
      element,
      sectionId,
      elementId,
    })
    triggerUpdate()
    selectElement(newElement)
    return newElement
  }

  const changeElementOrder = ({ from, to }) => {
    const moved = config.sections[from.sectionIndex]
      .variables.columns[from.columnIndex].elements.splice(from.elementIndex, 1)[0]

    config.sections[to.sectionIndex]
      .variables.columns[to.columnIndex].elements.splice(to.elementIndex, 0, moved)

    uniqueArray([from.sectionIndex, to.sectionIndex]).forEach((index) => {
      updateSectionInTemplate({ config, section: config.sections[index] })
    })
    triggerUpdate()
  }

  // Updates an element by id.
  // element, elementId: Accepts an element object or an elementId
  // update: object to merge over current configuration
  const updateElement = ({ element, update }) => {
    if (filterElementUpdate({ element, update })) {
      updateElementInSection({ config, element, update })
      if (updateSharedSection) {
        updateSharedSection(getSectionElement({ element }))
      } else {
        triggerUpdate()
      }
    }
  }

  const confirmDeleteElement = ({ element, sectionId }) => {
    const onConfirm = () => {
      removeElementFromSection({ config, element })
      selectSection(sectionId)
      triggerUpdate()
    }
    const { config: { text, src, content } } = element

    // If they have configured the element, ask before deleting
    if (text || src || content) {
      sendDialog({
        onConfirm,
        title: `Remove "${element.type}"?`,
        confirmText: 'Remove',
        destructive: true,
      })
    } else {
      onConfirm()
    }
  }

  // Handles the drop event for an image, uploading it and updating the element { src }
  const dropImage = uploadImage ? async ({ data, event }) => {
    const imageElement = '.enveloop-element[data-type=Image]'
    const el = event.target.closest(imageElement)

    if (el) {
      const { url } = await uploadImage(data[0])
      updateElement({ elementId: el.id, update: { src: url } })
    }
  } : null

  React.useEffect(() => {
    setConfig(configProp)
    setUpdated({})
  }, [configProp])

  // ====================
  // COPY & PASTE HELPERS
  // ====================

  const copySection = (section) => {
    let _theme = includeKeys(config.variables._theme || {}, sectionThemeValues({ section }))
    if (!Object.keys(_theme).length) _theme = null
    copy('section', { section: newSection({ section, templateVariables: config.variables }), _theme, sourceSlug: slug })
  }

  const pasteSection = ({
    section, sectionId, sourceSlug, _theme,
  }) => {
    if (slug !== sourceSlug) {
      pasteThemeNotice(_theme, config)
      updateTemplate({
        _theme: {
          ..._theme,
          ...config.variables._theme,
        },
        localOnly: true,
      })
    }
    addSection({ section, sectionId })
  }

  const copyElement = (element) => {
    let _theme = includeKeys(config.variables._theme || {}, elementThemeValues({ element }))
    if (!Object.keys(_theme).length) _theme = null
    copy('element', { element: copyObject(element), _theme, sourceSlug: slug })
  }

  const pasteElement = ({
    element, sectionId, elementId, _theme, sourceSlug,
  }) => {
    if (slug !== sourceSlug) {
      pasteThemeNotice(_theme)
      updateTemplate({
        _theme: {
          ..._theme,
          ...config.variables._theme,
        },
        localOnly: true, // Because adding the element will push an update
      })
    }
    addElement({ element, sectionId, elementId })
  }

  const copyTheme = () => {
    copy('theme', { _theme: config.variables._theme, sourceSlug: slug })
  }

  const pasteTheme = ({ _theme }) => {
    pasteThemeNotice(_theme, config)
    const newTheme = { ...(config.variables._theme || {}), ..._theme }
    updateTemplate({ _theme: newTheme })
  }

  const pasteSectionTheme = ({ _theme, sectionId }) => {
    const { section } = getTemplateSection({ config, sectionId })
    pasteThemeNotice(_theme, section)
    updateSharedSection?.({
      section, update: { _theme },
    })
  }

  useKeyUp({
    key: 'Backspace',
    onPress: () => {
      const { section, sectionId, element } = getTemplateState({ root, config })
      if (element) {
        confirmDeleteElement({ element, sectionId })
      } else if (section) {
        confirmDeleteSection({ section })
      }
    },
  })

  useKeyDown({
    key: ['cmd c', 'ctrl c'],
    onPress: () => {
      const { section, element } = getTemplateState({ root, config })
      if (element) {
        copyElement(element)
      } else if (section) {
        copySection(section)
      }
    },
  })

  useKeyDown({
    key: ['cmd v', 'ctrl v'],
    onPress: () => {
      const { sectionId, elementId } = getTemplateState({ root, config })
      const { name: type, data } = inspect()
      if (type === 'element') {
        addElement({ element: data, elementId, sectionId })
        clear()
      } else if (type === 'section') {
        addSection({ section: data, sectionId })
        clear()
      }
    },
  })

  React.useEffect(() => () => onChange.cancel(), [slug])

  const onDragStart = ({ active }) => setDragging(active.id)
  const onDragEnd = () => setDragging(undefined)

  return React.useMemo(() => ({
    ...rest,
    root,
    name,
    slug,
    config,
    theme,
    copySection,
    pasteSection,
    copyTheme,
    pasteTheme,
    pasteSectionTheme,
    updateSection,
    confirmDeleteSection,
    addSection,
    changeSectionOrder,
    updateTemplate,
    convertToSharedSection,
    unlinkSharedSection,
    SharedSectionPanel,
    sharedSections,
    showDrawerMenu,
    setShowDrawerMenu,
    addSharedSection,
    getSectionElement,
    copyElement,
    pasteElement,
    addElement,
    confirmDeleteElement,
    updateElement,
    dropImage,
    uploadImage,
    saveTemplate,
    changeElementOrder,
    dragging,
    onDragStart,
    onDragEnd,
    queryCount,
  }), [dragging, updated, name, slug, showDrawerMenu])
}

const TemplateConfigProvider = ({
  mode = 'builder',
  type,
  children,
  ...rest
}) => {
  const root = useTemplateRoot()
  const value = useTemplateConfigHooks({
    onChange: () => { },
    SharedSectionPanel: () => null,
    sharedSections: [],
    ...rest,
    mode,
    type,
    root,
  })

  const { sectionId, elementId } = getTemplateState({ ...rest, root })
  const state = React.useMemo(() => ({ path: { sectionId, elementId } }), [sectionId, elementId])

  return (
    <TemplateConfigContext.Provider value={value}>
      <TemplateProvider config={value.config} type={type} state={state} hooks={value} mode={mode}>
        {children}
      </TemplateProvider>
    </TemplateConfigContext.Provider>
  )
}

TemplateConfigProvider.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  saveToSharedSection: PropTypes.func,
  SharedSectionPanel: PropTypes.func,
  sharedSections: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  config: PropTypes.shape({
    sections: PropTypes.array,
    variables: PropTypes.object,
  }).isRequired,
  mode: PropTypes.string,
}

export {
  useTemplateConfigHooks,
  TemplateConfigProvider,
  useTemplateConfig
}
