import React from 'react'
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import {
  Main, Loading,
  Text, Breadcrumbs,
  Header, Spacer,
  NavPanel,
} from '@level'

import { TemplateBuilder } from './build'
import { TemplateTest } from './test'
import { TemplateDeploy } from './deploy'
import { useTemplate } from './context'
import { TemplateMenuActions } from './actions'

const Refresh = () => {
  const navigate = useNavigate()
  const location = useLocation()

  // Navigate back to previous route
  React.useEffect(() => {
    navigate(location.state?.from || '/default-path');
  }, [])

  return null
}

const TemplateShow = () => {
  const { template } = useTemplate()
  const templateNav = [
    { text: 'Build', to: './build' },
    { text: 'Test', to: './test' },
    { text: 'Deploy', to: './deploy' },
  ]

  return (
    <Main>
      <Header primary columns={3}>
        <Breadcrumbs size={3}>
          <Text>{template?.name || <Loading />}</Text>
        </Breadcrumbs>
        <Spacer grow />
        <NavPanel navItems={templateNav} />
        <Spacer grow />
        <TemplateMenuActions />
      </Header>
      <Routes>
        <Route path="*" element={<Navigate to="build" replace />} />
        <Route path="refresh" element={<Refresh />} />
        <Route path="build/*" element={<TemplateBuilder />} />
        <Route path="test" element={<TemplateTest />} />
        <Route path="deploy/*" element={<TemplateDeploy />} />
      </Routes>
    </Main>
  )
}

export {
  TemplateShow,
}
