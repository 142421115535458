import {
  copyObject,
  deepMerge,
  dotKeysToObject,
  filterEmptyValues,
  getTemplateElement,
  getTemplateSection,
  includeKeys,
  jsxToString,
  removeSectionTheme,
  sortKeys,
  updateSectionTheme,
  updateTemplateTheme,
} from '../../../helpers'
import { configDefaults, newColumnConfig } from './Config/defaults'
import Elements, {
  getElementDefault,
  newElement, onUpdateElementConfig,
} from './Element'
import { exportSection, newSection, renderSection } from './Section'
import { EmailTemplate } from './template'

const renderEmailTemplate = ({ config, mode = 'render' }) => {
  const wrapper = jsxToString(<EmailTemplate config={config} type="email" mode={mode} />)
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 3.2//EN">${wrapper}`
}

const copyEmailTemplate = ({ config: templateConfig, ...rest }) => {
  const config = {
    ...templateConfig,
    sections: templateConfig.sections.map((section) => newSection({
      section,
      templateVariables: templateConfig.variables,
    })),
  }

  return {
    ...rest,
    config: {
      ...config,
      body: renderEmailTemplate({ config }),
    },
  }
}

const exportEmailTemplate = ({ config }) => ({
  variables: {
    ...config.variables,
    style: filterEmptyValues(config.variables.style),
  },
  sections: config.sections.map(exportSection),
})

// If vars appear to be nested in a conditional var, remove the conditional var from the prefix.
// For example, if `coupon` is a condtional var, and we have a template with this:
// {{#coupon}}
//   {{ coupon }}
//   {{ code }}
// {{/coupon}}
// We'd have the following vars: `coupon`, `coupon.coupon` and `coupon.code`
// This function removes the coupon prefix so we have `coupon` and `code` instead
const fixConditionalVars = ({ vars, conditional }) => (
  conditional?.length ? vars.map((v) => {
    const match = conditional.find((c) => v.startsWith(`${c}.`))
    return match ? v.replace(`${match}.`, '') : v
  }) : vars
)

// Iterable vars should have child vars in an array instead of just object children.
// Example, if `items` is an iterable var, an object with this shape:
// items: {
//   name: 'product',
//   price: '$10.00',
// }
// Will be converted to:
// items: [
//   {
//     name: 'product',
//     price: '$10.00',
//   },
// ]

const fixIterableVars = ({ vars, iterable }) => (
  iterable?.length ? Object.keys(vars).reduce((acc, key) => {
    if (iterable.includes(key)) {
      if (acc[key] && !Array.isArray(acc[key])) {
        acc[key] = [acc[key]]
      }
    }
    return acc
  }, vars) : vars
)

const getEmailTemplateVars = ({ template, previewVars = {}, config = 'previewConfig' }) => {
  const { conditional, iterable } = EmailTemplate.getMustacheTypes({ template, config }) || {}
  // Remove nested _theme values from vars
  const filteredVars = (template?.previewTemplateVariables || []).filter((v) => !v.includes('._theme'))
  // Merge user vars pulled from templates with any values in the editor
  const cond = fixConditionalVars({ vars: filteredVars, conditional })
  const obj = dotKeysToObject(cond)
  const sortedVars = previewVars
    ? sortKeys(deepMerge(obj, includeKeys(previewVars, obj)))
    : sortKeys(obj)
  const iv = fixIterableVars({ vars: sortedVars, iterable })
  return iv
}

const templateSectionIndex = ({ config, section }) => (
  config.sections.findIndex(({ variables }) => (
    variables.id === section?.variables?.id
  ))
)

// ELEMENT MANAGEMENT
// ==================

const elementInsertionPosition = ({ config, section, elementId }) => {
  const { elementIndex, columnIndex } = getTemplateElement({
    config, sectionId: section.id, elementId,
  })
  return {
    elementIndex: elementIndex + 1,
    columnIndex,
  }
}

// Add an element to a section in the column.
// - name: will look up the element by name
// - config: default settings for the new element
const addElementToSection = ({
  config, // required: templateConfig
  element, // required: element: { type, config }
  elementId, // optional: insert after element with this id
  sectionId, // optional: If section object not avaialable it can be found from id
  section = sectionId ? getTemplateSection({ config, sectionId }).section : null,
  ...props
}) => {
  let {
    columnIndex = 0,
    elementIndex = section.variables.columns[columnIndex].elements.length,
  } = props

  if (elementId) {
    const pos = elementInsertionPosition({ config, section, elementId })
    columnIndex = pos.columnIndex
    elementIndex = pos.elementIndex
  }

  const newElementConfig = newElement({
    element: Elements[element.type],
    config: element.config,
    templateVariables: config.variables,
  })

  section.variables.columns[columnIndex].elements.splice(elementIndex, 0, newElementConfig)
  section.body = renderSection({
    section, templateVariables: config.variables,
  }).body
  return {
    element: newElementConfig,
    section,
  }
}

const removeElementFromSection = ({
  config, // required: temlateConfig
  element, // optional if elementId is provided
  elementId = element?.id, // optional: If element object isn't available it can be found from  id
}) => {
  const { section, columnIndex, elementIndex } = getTemplateElement({ config, elementId })
  section.variables.columns[columnIndex].elements.splice(elementIndex, 1)
  return section
}

const filterElementUpdate = ({ element, update }) => {
  const updates = includeKeys(update, getElementDefault(element))
  return Object.keys(updates).length ? update : null
}

const updateElementInSection = ({
  update, // Required: element config object
  config, // Required: Template config
  element: Element, // optional: if elementId is provided
  elementId = Element?.id, // Optional: If element object isn't provided it can be found from id
}) => {
  const {
    section, element, column, columnIndex, elementIndex, varPath,
  } = getTemplateElement({ config, elementId })
  // Only permit change if it is found in initial object default
  const updates = filterElementUpdate({ element, update })

  if (updates) {
    const newConfig = onUpdateElementConfig({
      element: {
        ...element,
        config: { ...element.config, ...updates },
        varPath,
        columnConfig: column.config,
        sectionConfig: section.variables.config,
        templateVariables: config.variables,
      },
    })
    section.variables
      .columns[columnIndex]
      .elements[elementIndex]
      .config = newConfig
    section.body = renderSection({
      section, templateVariables: config.variables,
    }).body
  }
  return { element: section.variables.columns[columnIndex].elements[elementIndex], section }
}

// SECTION MANAGEMENT
// ==================

// Insert or remove sections from template sections config
const spliceSection = ({
  config,
  section,
  index = section ? templateSectionIndex({ config, section }) : config.sections.length,
  replace,
}) => {
  const spliceArgs = [index, replace ? 1 : 0]
  if (section) spliceArgs.push(section)
  config.sections.splice(...spliceArgs)
}

// Find the index where a section will be inserted, if a sesctionId is provided, insert it after
const sectionInsertionIndex = ({ config, sectionId }) => {
  const section = sectionId ? getTemplateSection({ config, sectionId }) : null
  return section?.section
    ? section.sectionIndex + 1
    : config.sections.length
}

const addSectionToTemplate = ({
  config, section, sectionId, index, replace,
}) => {
  const renderedSection = newSection({ section, templateVariables: config.variables })
  spliceSection({
    config,
    section: renderedSection,
    index: index || sectionInsertionIndex({ config, sectionId }),
    replace,
  })
  return renderedSection
}

const removeSectionFromTemplate = ({
  config, section, index = templateSectionIndex({ config, section }),
}) => {
  spliceSection({ config, index, replace: true })
}

const updateSectionType = ({ section, type, variable }) => {
  if (type) {
    section.variables.config.type = type
  }
  if (variable) {
    section.variables.config.variableStart = `{{#${variable}}}`
    section.variables.config.variableEnd = `{{/${variable}}}`
  }
}

const updateSectionColumns = ({ section, columnLayout }) => {
  if (!columnLayout) return
  const { columns } = section.variables
  // The input passes back proportions separated by columns.
  // `100%` is a single column. `50%:50%` is two columns, `33%:34%:33%` is three columns, etc
  // By splitting on the colon we can get the widths, and the number of columns.
  const widths = columnLayout.split(':')

  for (
    let toRemove = 0;
    toRemove < (section.variables.columns.length - widths.length);
    toRemove += 1
  ) {
    // Remove last column
    const { elements } = section.variables.columns.pop()
    // If there are elements in that column
    if (elements.length) {
      // Splice the elements into the last remaining column's list of elements
      section.variables.columns[section.variables.columns.length - 1].elements.splice(
        section.variables.columns[section.variables.columns.length - 1].elements.length,
        0,
        ...elements,
      )
    }
  }

  // If columns were added, add a new empty column configuration
  for (let toAdd = 0; toAdd < (widths.length - columns.length); toAdd += 1) {
    section.variables.columns.push(newColumnConfig())
  }

  // set new widths for columns
  widths.forEach((width, index) => {
    section.variables.columns[index].config.width = width
  })
}

const updateSectionConfig = ({ section, update = {} }) => {
  updateSectionType({ section, ...update })
  updateSectionColumns({ section, ...update })

  if (typeof update._theme !== 'undefined') {
    updateSectionTheme({ section, ...update })
  }

  if (update.sharedSectionId) {
    section.sharedSectionId = update.sharedSectionId
  }
  if (update?.config) {
    section.variables.config = {
      ...section.variables.config,
      ...includeKeys(update.config, configDefaults.section),
    }
  }
}

const updateSectionInTemplate = ({ config, section, update }) => {
  updateSectionConfig({ section, update })

  const renderedSection = renderSection({
    section, templateVariables: config.variables,
  })

  if (config.sections) {
    spliceSection({
      config,
      section: renderedSection,
      index: templateSectionIndex({ section: renderedSection, config }),
      replace: true,
    })
  }
  return renderedSection
}

const unlinkSharedSectionFromTemplate = ({ config, section }) => {
  const sectionCopy = copyObject(section)
  delete sectionCopy.sharedSectionId
  removeSectionTheme({ section: sectionCopy, config })

  updateSectionInTemplate({
    section: sectionCopy,
    config,
  })
}

// TEMPLATE MANAGEMENT
// ==================

const updateTemplateConfig = ({ config, update }) => {
  if (update.style) config.variables.style = update.style
  if (typeof update._theme !== 'undefined') {
    updateTemplateTheme({ config, _theme: update._theme })
  }
  if (update.style || typeof update._theme !== 'undefined') {
    config.sections = config.sections.map((section) => (
      renderSection({ section, templateVariables: config.variables })
    ))
  }
  if (update.config) {
    config.variables.config = update.config
  }
  return config
}

export {
  addElementToSection, addSectionToTemplate, copyEmailTemplate,
  exportEmailTemplate, filterElementUpdate, getEmailTemplateVars,
  removeElementFromSection, removeSectionFromTemplate, renderEmailTemplate,
  unlinkSharedSectionFromTemplate, updateElementInSection, updateSectionConfig,
  updateSectionInTemplate, updateTemplateConfig
}
